import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useForm } from "react-hook-form";
import { emptyMessageReducer } from "../redux/userRedux";
import moment from "moment";
import { changePassword, sendOtp } from "../redux/APIs/UserAPIs";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #55caca4f;
`;
const Wrapper = styled.div`
  padding: 20px;
  width: 25%;
  background-color: white;
  ${mobile({ width: '75%'})}
  ${tabletPortrait({ width: '35%'})}
  ${tabletLandscape({ width: '35%'})}
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
  border: ${(props) => props.isValid ? '1px red solid' : '1px solid black'};
  border-radius: 2px;
  &: focus{
    outline: none;
  }
`;
const Link = styled.span`
  margin: 10px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;
const Button = styled.button`
  width: fit-content;
  border: none;
  padding: 12px 13px;
  background-color: teal;
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
  &:disabled{
    color: white;
    cursor: not-allowed;
    background-color: #55caca4f;
  }
`;

const ErrorTag = styled.span`
  color: red;
`;
const Message = styled.span`
  color: green;
`;

const ForgotPassword = () => {
  const { isFetching, error, isLoggedIn, message } = useSelector(state => state.user);
  const [otpSent, setOtpSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const onSubmit = (data) => {
    let updatedAt = moment().utc().format();
    let user = {...data, updatedAt};
    if(!otpSent) {
        sendOtp(dispatch, data);
    } 
    else {
        changePassword(dispatch, user);
    }
  } 
  const redirect = () => {
    navigate("/")
  }

  useEffect(() => {
    isLoggedIn && redirect();
    // eslint-disable-next-line
  }, [isLoggedIn])

  useEffect(() => {
    dispatch(emptyMessageReducer());
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    if(error)
    setErrorMsg(message);
    if(message === 'OTP sent successfully.'){
        setOtpSent(true);
    }
    if(message === ''){
        setOtpSent(false);
    }
    if(message === 'Password Changed Successfully.') {
        navigate("/login");
    }
    // eslint-disable-next-line
  }, [message])
  

  
  return (
    <Container>
      <Wrapper>
        <Title>RESET YOUR PASSWORD</Title>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Form>
            {otpSent && <Message>OTP Sent Successfully!</Message>}
            <Input {...register('email', // eslint-disable-next-line
             { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ })} isValid={errors?.email} type={"text"} placeholder="email" />
            {otpSent && <><Input {...register('password', { required: true })} isValid={errors?.password} type={"password"} placeholder="New Password" />
            <Input {...register('cnfPassword', { required: true, validate: (value) => { let password = getValues("password"); return password === value} })} isValid={errors?.cnfPassword} type={"password"} placeholder="Confirm New Password" />
            <Input {...register('otp', { required: true, minLength: 4 })} isValid={errors?.otp} type={"number"} placeholder="Enter OTP" /></>}
            <Button type="submit" disabled={isFetching}>{!otpSent ? 'SEND OTP' : 'CHANGE PASSWORD'}</Button>
            {error && <ErrorTag>{errorMsg}</ErrorTag>}
            <NavLink style={{ textDecoration: "none", color: "black" }} to={"/login"}>
              <Link>GO TO LOGIN PAGE</Link>
            </NavLink>
          </Form>
        </form>
      </Wrapper>
    </Container>
  );
};

export default ForgotPassword;