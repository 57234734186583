import React, { useEffect } from "react";
import styled from "styled-components";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { emptyMessageReducer } from "../redux/userRedux";
import { signupVerify } from "../redux/APIs/UserAPIs";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #55caca4f;
`;
const Wrapper = styled.div`
  padding: 20px;
  width: 40%;
  background-color: white;
  ${mobile({ width: "70%", margin: "30px" })}
  ${tabletPortrait({ width: "80vw", margin: "30px" })}
  ${tabletLandscape({ width: "80vw", margin: "30px" })}
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  ${mobile({ fontSize: "20px" })}
`;
const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid black")};
  border-radius: 2px;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  &:focus {
    outline: none;
  }
  ${mobile({ padding: "8px", margin: "10px 5px 0px 0px" })}
`;
const Button = styled.button`
  width: 40%;
  border: none;
  padding: 11px 12px;
  margin: 15px 0px;
  background-color: teal;
  cursor: pointer;
  font-size: 16px;
  color: white;
  &:disabled {
    color: white;
    cursor: not-allowed;
    background-color: #55caca4f;
  }
  ${mobile({ width: "75%", padding: "8px 10px" })}
`;
const Error = styled.div`
  color: red;
  margin-top: 10px;
`;

const VerifyRegistration = () => {
  const response = useSelector((state) => state.user);
  const isFetching = useSelector((state) => state.user.isFetching);
  // const error = useSelector((state) => state.user.message);
  // const [errors, setErrors] = useState({});
  // const [errorMsg, setErrorMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email:
        response.currentUser &&
        response.currentUser.userDetails &&
        response.currentUser.userDetails.email,
      mobile:
        response.currentUser &&
        response.currentUser.userDetails &&
        response.currentUser.userDetails.mobile,
    },
  });
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    signupVerify(dispatch, values);
  };

  useEffect(() => {
    dispatch(emptyMessageReducer());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (response.message === "User Created") {
      navigate("/login", { state: { newUser: true } });
    }
    // eslint-disable-next-line
  }, [response]);

  return (
    <Container>
      <Wrapper>
        <Title>VERIFY YOUR ACCOUNT</Title>
        <Form>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            {process.env.REACT_APP_VERIFICATION_SERVICE === "email" ? (
              <Input
                {...register("email", {
                  required: true, // eslint-disable-next-line
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                })}
                isValid={errors?.email ? true : false}
                type="email"
                placeholder="E-mail"
                name="email"
              />
            ) : (
              <Input
                {...register("mobile", { required: true, minLength: 10 })}
                isValid={errors?.mobile ? true : false}
                type="number"
                placeholder="Mobile"
                name="mobile"
              />
            )}
            <Input
              {...register("otp", { required: true, minLength: 4 })}
              isValid={errors?.otp ? true : false}
              type={"number"}
              placeholder="Enter OTP"
            />
            <Button disabled={isFetching} type="submit">
              CREATE ACCOUNT
            </Button>
          </form>
        </Form>
        <Error>{""}</Error>
      </Wrapper>
    </Container>
  );
};

export default VerifyRegistration;
