import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { login, sendLoginOtp } from "../redux/APIs/UserAPIs"; // Assuming sendOtp API is created for OTP functionality
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useForm } from "react-hook-form";
import { emptyMessageReducer, emptyReducer } from "../redux/userRedux";
import { resetReducer } from "../redux/registrationRedux";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #55caca4f;
`;

const Wrapper = styled.div`
  padding: 20px;
  width: 35%;
  background-color: white;
  ${mobile({ width: "85%" })}
  ${tabletPortrait({ width: "50%" })}
  ${tabletLandscape({ width: "35%" })}
`;

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  ${mobile({ flexDirection: "row" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid black")};
  border-radius: 2px;
  &:focus {
    outline: none;
  }
`;

const Radio = styled.input`
  margin-right: 5px;
  &:checked {
    background-color: teal;
  }
`;

const Label = styled.label`
  margin: 10px 10px 10px 0px;
  font-weight: 500;
`;

const Link = styled.span`
  margin: 10px 0px 0px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

const LinkWoMg = styled.span`
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

const Button = styled.button`
  width: 35%;
  border: none;
  padding: 12px 13px;
  background-color: teal;
  margin-right: 20px;
  color: white;
  cursor: pointer;
  &:disabled {
    color: white;
    cursor: not-allowed;
    background-color: #55caca4f;
  }
  ${mobile({ width: "45%", padding: "8px 10px" })}
`;

const Error = styled.span`
  color: red;
`;

const Login = () => {
  const { isFetching, error, isLoggedIn, currentUser, message } = useSelector(
    (state) => state.user
  );
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loginMethod, setLoginMethod] = useState("otp"); // email or otp
  const [otpSent, setOtpSent] = useState(false); // Track OTP sent status
  const [otp, setOtp] = useState(""); // Store OTP input

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const location = useLocation();

  const onSubmit = async (data) => {
    if (loginMethod === "email") {
      login(dispatch, data);
    } else if (loginMethod === "otp" && otpSent) {
      login(dispatch, { mobile: data.mobile, otp });
    }
  };

  const handleOtpSend = () => {
    const mobile = getValues("mobile");
    sendLoginOtp(dispatch, { mobile });
  };

  const resetForm = () => {
    setOtpSent(false);
    reset();
    dispatch(emptyReducer());
    dispatch(emptyMessageReducer());
  };

  const redirect = () => {
    if (location.state && location.state.url) {
      window.location.href = location.state.url;
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      currentUser !== null &&
        localStorage.setItem("key", currentUser.accessToken);
      redirect();
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  useEffect(() => {
    if (location.state && location.state.newUser === true) {
      setSuccessMsg("User Created Successfully!");
    }
    if (!isLoggedIn) {
      dispatch(emptyReducer());
      dispatch(emptyMessageReducer());
      setOtpSent(false);
    }
    dispatch(resetReducer());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) setErrorMsg(message);
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (message === "OTP sent successfully") {
      setSuccessMsg(message);
      setOtpSent(true);
    } else {
      setSuccessMsg("");
    }
  }, [message]);

  return (
    <Container>
      <Wrapper>
        <Title>SIGN IN</Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form>
            <p style={{ color: "green", margin: "0px" }}>{successMsg}</p>
            <div>
              <Label>Login with:</Label>
              <Radio
                type="radio"
                id="otp-login"
                name="login-method"
                value="otp"
                checked={loginMethod === "otp"}
                onChange={() => {setLoginMethod("otp"); resetForm();}}
              />
              <Label htmlFor="otp-login">Mobile OTP</Label>
              <Radio
                type="radio"
                id="email-login"
                name="login-method"
                value="email"
                checked={loginMethod === "email"}
                onChange={() => {setLoginMethod("email"); resetForm();}}
              />
              <Label htmlFor="email-login">Email</Label>
            </div>

            {loginMethod === "email" && (
              <Input
                {...register("email", { required: true })}
                isValid={errors?.email ? true : false}
                type="text"
                placeholder="Email Address"
              />
            )}

            {loginMethod === "otp" && (
              <Input
                {...register("mobile", { required: true })}
                isValid={errors?.mobile ? true : false}
                type="text"
                placeholder="Mobile Number"
                disabled={otpSent || isFetching}
              />
            )}

            {loginMethod === "email" && (
              <Input
                {...register("password", { required: true, minLength: 5 })}
                isValid={errors?.password ? true : false}
                type="password"
                placeholder="Password"
              />
            )}

            {loginMethod === "otp" && otpSent && !isFetching && (
              <Input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                type="text"
                placeholder="Enter OTP"
              />
            )}

            {loginMethod === "otp" && !otpSent && (
              <Button disabled={false} onClick={handleOtpSend}>
                SEND OTP
              </Button>
            )}

            <BtnContainer>
              {(loginMethod === "email" ||
                (loginMethod === "otp" && otpSent)) && (
                <Button type="submit" disabled={isFetching}>
                  {loginMethod === "email" ? "LOGIN" : "VERIFY OTP"}
                </Button>
              )}
              {loginMethod === "otp" && otpSent && (
                <LinkWoMg
                  onClick={() => {
                    resetForm();
                  }}
                >
                  USE ANOTHER NUMBER?
                </LinkWoMg>
              )}
            </BtnContainer>

            {error && <Error>{errorMsg}</Error>}

            {loginMethod === 'email' && <Link>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to={"/forgotPassword"}
              >
                FORGOT YOUR PASSWORD?
              </NavLink>
            </Link>}

            <Link>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to={"/register"}
              >
                CREATE A NEW ACCOUNT
              </NavLink>
            </Link>
          </Form>
        </form>
      </Wrapper>
    </Container>
  );
};

export default Login;
