import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL + "/api";
const SAATHI_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const publicRequest = axios.create({
    baseURL: BASE_URL
})

export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: {token: `Bearer ${localStorage.getItem('key')}`}
    // headers: {token: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo0LCJmaXJzdF9uYW1lIjoiVmFpYmhhdiIsImxhc3RfbmFtZSI6IlNhaW5pIiwidXNlcm5hbWUiOiJhZG1pbiIsImVtYWlsIjoidmFpYmhhdnNhaW5pOTA0QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJiJDEwJDJPcFFyajgyOEF3RkdWbUh4SDdNL3VWcmVRbVIubnlOMWVzek4xUHZOcHN0bkh1TWREbmZTIiwiY3JlYXRlZF9hdCI6IjIwMjMtMDUtMjdUMTg6MjM6NTUuMDAwWiIsInVwZGF0ZWRfYXQiOiIyMDIzLTA1LTI3VDExOjUyOjUwLjAwMFoiLCJyb2xlIjoidHJ1ZSIsImlzdmVyaWZpZWQiOnRydWV9LCJpYXQiOjE2OTgwNjQ4MTMsImV4cCI6MTY5ODA3NTYxM30.Ldy0lDLml8jA8Qh6nrdqhUXPyV_kW9oeb7-ebi1xoZo`}
});

export const saathiRequest = axios.create({
    baseURL: SAATHI_BASE_URL,
    headers: {token: `Bearer ${localStorage.getItem('key')}`}
    // headers: {token: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo0LCJmaXJzdF9uYW1lIjoiVmFpYmhhdiIsImxhc3RfbmFtZSI6IlNhaW5pIiwidXNlcm5hbWUiOiJhZG1pbiIsImVtYWlsIjoidmFpYmhhdnNhaW5pOTA0QGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiJDJiJDEwJDJPcFFyajgyOEF3RkdWbUh4SDdNL3VWcmVRbVIubnlOMWVzek4xUHZOcHN0bkh1TWREbmZTIiwiY3JlYXRlZF9hdCI6IjIwMjMtMDUtMjdUMTg6MjM6NTUuMDAwWiIsInVwZGF0ZWRfYXQiOiIyMDIzLTA1LTI3VDExOjUyOjUwLjAwMFoiLCJyb2xlIjoidHJ1ZSIsImlzdmVyaWZpZWQiOnRydWV9LCJpYXQiOjE2OTgwNjQ4MTMsImV4cCI6MTY5ODA3NTYxM30.Ldy0lDLml8jA8Qh6nrdqhUXPyV_kW9oeb7-ebi1xoZo`}
});