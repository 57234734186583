import { createSlice } from "@reduxjs/toolkit";

const memberSlice = createSlice({
  name: "saathiMember",
  initialState: {
    members: [],
    isFetching: false,
    error: "",
  },
  reducers: {
    getMembersStart: (state, action) => {
      state.isFetching = true;
    },
    getMembersSuccess: (state, action) => {
      state.members = action.payload;
      state.isFetching = false;
    },
    getMembersFailure: (state, action) => {
      state.isFetching = false;
      state.error = "error";
    },
    addMembersStart: (state, action) => {
      state.isFetching = true;
    },
    addMembersSuccess: (state, action) => {
      state.members = action.payload.data;
      state.isFetching = false;
    },
    addMembersFailure: (state, action) => {
      state.isFetching = false;
      state.error = "error";
    },
    emptyMembersReducer: (state) => {
      state.members = {};
      state.isFetching = false;
    },
  },
});

export const {
  getMembersStart,
  getMembersSuccess,
  getMembersFailure,
  addMembersFailure,
  addMembersStart,
  addMembersSuccess,
  emptyMembersReducer,
} = memberSlice.actions;
export default memberSlice.reducer;
