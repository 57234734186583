import { userRequest } from "../../RequestMethods";
import { cartActionAddSuccess, cartActionFailure, cartActionStart } from "../cartRedux";

export const addCartItem = async (dispatch, item) => {
  dispatch(cartActionStart());
  await userRequest
    .post("/cart/addCartItem", item)
    .then((resp) => {
      dispatch(cartActionAddSuccess(resp.data));
    })
    .catch((err) => {
      console.error(err)
      dispatch(cartActionFailure());
    });
};

export const getAllCartItems = async (dispatch) => {
  dispatch(cartActionStart());
  await userRequest
    .get("/cart/getAllCartItems")
    .then((resp) => {
      dispatch(cartActionAddSuccess(resp.data));
    })
    .catch((err) => {
      console.error(err)
      dispatch(cartActionFailure());
    });
};

export const deleteCartItem = async (dispatch, id) => {
  dispatch(cartActionStart());
  await userRequest
    .delete("/cart/deleteCartItem/" + id)
    .then((resp) => {
    
      dispatch(cartActionAddSuccess(resp.data));
    })
    .catch((err) => {
      console.error(err)
      dispatch(cartActionFailure());
    });
};

export const updateCartItem = async (dispatch, product) => {
  dispatch(cartActionStart());
  let config = {
    headers: {
      query: JSON.stringify(product)
    }
  }
  await userRequest
    .patch("/cart/updateCartItem", '', config)
    .then((resp) => {
      
      dispatch(cartActionAddSuccess(resp.data));
    })
    .catch((err) => {
      console.error(err)
      dispatch(cartActionFailure());
    });
};

