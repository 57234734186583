import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  makePaymentByRazorPay,
  makePaymentByPhonePe,
} from '../redux/APIs/PaymentAPIs';
import { mobile, tabletLandscape, tabletPortrait } from '../responsive';
import { List, ListItem, ListItemButton, ListItemIcon, Radio } from '@mui/material';
import phonepe from '../Assets/Logo/phonepe.png';
import razorpay from '../Assets/Logo/razorpay.png';

// const Button = styled.button`
//   width: 100%;
//   font-wight: 600;
//   padding: 10px;
//   background-color: black;
//   color: white;
//   border: none;
//   cursor: pointer;
//   &:disabled {
//     background-color: #484646;
//     border: none;
//     cursor: not-allowed;
//   }
// `;

const Container = styled.div`
  padding: 0px 20px;
`;

const Button = styled.button`
  width: 22%;
  border: none;
  padding: 10px;
  margin: 20px 10px 0px 0px;
  background-color: teal;
  cursor: pointer;
  color: white;
  font-weight: 500;
  &:disabled {
    border: none;
    cursor: not-allowed;
  }
  ${mobile({ width: '55%', padding: '8px 10px' })}
  ${tabletPortrait({ width: '35%', padding: '8px 10px' })}
  ${tabletLandscape({ width: '35%', padding: '8px 10px' })}
`;

const Title = styled.h3`
  font-weight: 400;
`;

const Image = styled.img``;

const Pay = ({ cart, selectedAddress }) => {
  const cartItems = cart.cartItems;
  const shippingAmount = cart.shippingCharges;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [btnClicked, setBtnClicked] = useState(false);
  const [paymentGateway, setPaymentGateway] = useState('PhonePe')

  // const handleCheckout = async () => {
  //   let payload = [];
  //   cartItems.products.map((item) => {
  //     let product = {
  //       price_data: {
  //         currency: "INR",
  //         product_data: {
  //           name: item.title,
  //           // images: item.img,
  //           metadata: {
  //               id: item.id
  //           }
  //         },
  //         unit_amount: Number(item.price*100),
  //       },
  //       quantity: item.quantity,
  //     };
  //     payload.push(product);
  //     return product;
  // });
  //   let data = {
  //       payload: payload,
  //       userId: userId
  //   }
  //   makePayment(dispatch, data);
  // };

  // useEffect(() => {
  //   if (stripe.message === "Success") {
  //     navigate("/orderSuccess");
  //   }
  // }, [stripe.message]);

  const handleCheckout = async () => {
    setBtnClicked(true);
    let data = {
      amount: Number(cartItems.total) + Number(shippingAmount),
      user,
      delAdd: selectedAddress,
    };
    if(paymentGateway === 'PhonePe') {
      makePaymentByPhonePe(dispatch, data, cartItems.products, selectedAddress, cartItems.subMembers);
    } else {
      makePaymentByRazorPay(dispatch, data, cartItems.products, selectedAddress, cartItems.subMembers);
    }
  };
  
  return (
    <Container>
      <Title>Choose Payment Option</Title>

      <List sx={{ width: "100%", maxWidth: 360 }}>
        <ListItem key={0}>
          <ListItemButton
            role={undefined}
            onClick={() => setPaymentGateway('PhonePe')}
            dense
          >
            <ListItemIcon>
              <Radio checked={paymentGateway === 'PhonePe'} />
            </ListItemIcon>
            <Image src={phonepe} height={40} />
          </ListItemButton>
        </ListItem>
        <ListItem key={1}>
          <ListItemButton
            role={undefined}
            onClick={() => setPaymentGateway('Razorpay')}
            dense
          >
            <ListItemIcon>
              <Radio checked={paymentGateway === 'Razorpay'} />
            </ListItemIcon>
            <Image src={razorpay} height={40} />
          </ListItemButton>
        </ListItem>
      </List>

      <Button disabled={cartItems.total === 0 || btnClicked} onClick={() => handleCheckout()}>
        Proceed To Pay
      </Button>
    </Container>
  );
};

export default Pay;
