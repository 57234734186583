import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useNavigate } from "react-router-dom";
import bannerImage from "../Assets/banner.jpeg";

const Wrapper = styled.div`
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 20px;
    width: 60%;
    height: 500px;
    background-color: #fb984c;
    color: #780000;
    border: 1px solid rgb(105, 150, 171);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    text-shadow: 4px 4px 6px rgba(255, 255, 255, 0.8); /* Add text outline for more visibility */
    ${mobile({ width: "75%", height: "350px" })}
    ${tabletPortrait({ width: "50%", height: "400px" })}
    ${tabletLandscape({ width: "65%", height: "400px" })}

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${(props) => props.bgImage ?? ""}); /* Use props for dynamic path */
        background-size: cover;
        background-position: center;
        opacity: 0.7; /* Decrease opacity for better text visibility */
        z-index: -1; /* Ensure the image is behind the text */
        border-radius: 8px;
    }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: 4px 4px 6px rgba(255, 255, 255, 0.8); /* Add text outline for more visibility */
  z-index: 999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #780000;
  cursor: pointer;
`;

const AdvertisementBanner = () => {
  const [isVisible, setIsVisible] = useState(true);
  const bannerRef = useRef();
  const navigate = useNavigate();

  // Hide banner if user clicks outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (bannerRef.current && !bannerRef.current.contains(e.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  return (
    <>
      {isVisible && (
        <>
          <Overlay />
          <Wrapper
            ref={bannerRef}
            onClick={() => navigate("/registerSaathiMilaap")}
            bgImage={bannerImage} // Pass image as prop
          >
            <CloseButton
              onClick={(e) => {
                e.stopPropagation(); // Prevent event bubbling to Wrapper
                setIsVisible(false);
              }}
            >
              ×
            </CloseButton>
            <h2>Register your Family for Saathi Milaap!</h2>
            <p>
              Ensure your family’s safety and enjoy the event worry-free.
              Register now!
            </p>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default AdvertisementBanner;