import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    isLoggedIn: false,
    message: "",
  },
  reducers: {
    loginStart: (state, action) => {
      state.isFetching = true;
      state.error = false;
      state.message = "";
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = false;
      state.isLoggedIn = true;
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.isLoggedIn = false;
      state.error = true;
      state.message = action.payload;
    },
    changePasswordStart: (state, action) => {
      state.isFetching = true;
    },
    changePasswordSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.message = action.payload;
    },
    changePasswordFailure: (state, action) => {
      state.isFetching = false;
      state.isLoggedIn = false;
      state.error = true;
      state.message = action.payload;
    },
    singupStart: (state, action) => {
      state.isFetching = true;
    },
    signupSuccess: (state, action) => {
      state.isFetching = false;
      state.message = "Verification";
      state.currentUser = action.payload;
    },
    signupFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.message = action.payload.message;
    },
    signUpVerifyStart: (state, action) => {
      state.isFetching = true;
      state.currentUser = null;
      state.message = "";
    },
    signupVerifySuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.message = "User Created";
    },
    signupVerifyFailure: (state, action) => {
      state.isFetching = false;
      state.currentUser = null;
      state.error = true;
      state.message = action.payload.message;
    },
    sendLoginOTPStart: (state, action) => {
      state.isFetching = true;
      state.error = false;
      state.message = "";
    },
    sendLoginOTPSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.isLoggedIn = false;
      state.message = action.payload.msg;
    },
    sendLoginOTPFailure: (state, action) => {
      state.isFetching = false;
      state.isLoggedIn = false;
      state.error = true;
      state.message = action.payload;
    },
    emptyReducer: (state) => {
      state.currentUser = null;
      state.isLoggedIn = false;
      state.isFetching = false;
      state.error = false;
    },
    emptyMessageReducer: (state) => {
      state.message = "";
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  singupStart,
  signupSuccess,
  signupFailure,
  emptyReducer,
  emptyMessageReducer,
  changePasswordFailure,
  changePasswordSuccess,
  changePasswordStart,
  signupVerifyFailure,
  signupVerifySuccess,
  signUpVerifyStart,
  sendLoginOTPStart,
  sendLoginOTPSuccess,
  sendLoginOTPFailure,
} = userSlice.actions;
export default userSlice.reducer;
