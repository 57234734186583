import React from "react";
import styled from "styled-components";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";

const Container = styled.div`
  height: 30px;
  background-color: #d98a4a;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0 20px;
  ${mobile({ fontSize: "13px", height: "74px" })}
  ${tabletPortrait({ fontSize: "14px", height: "55px" })}
  ${tabletLandscape({ fontSize: "14px", height: "50px" })}
`;

const AnnouncementsSaathi = () => {
  return (
    <Container>
      Register your family and let us ensure their safety, so you can focus on
      your prayers, not your worries at the cost of just Rs. 50 !
    </Container>
  );
};

export default AnnouncementsSaathi;
