import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Announcements from "../components/Announcements";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NewsLetter from "../components/NewsLetter";
import Products from "../components/Products";
import { mobile } from "../responsive";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { getAllProd } from "../redux/APIs/ProductAPIs";

const Container = styled.div``;
const Title = styled.h1`
  margin: 20px;
  ${mobile({ fontSize: "24px" })}
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  ${mobile({ width: "0px 20px", display: "flex", flexDirection: "column" })}
`;

const FilterText = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
  ${mobile({ marginRight: "0px" })}
`;

const Button = styled.button`
  border: none;
  padding: 5px 12px;
  margin: 20px;
  background-color: #05648e;
  color: white;
  ${mobile({ fontSize: '14px', margin: '25px', padding: '2px 5px', width: '220px', height: '40px' })}
`;

const SearchContainer = styled.div`
  border: 1px solid black;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 2px;
  ${mobile({ marginTop: '8px' })}
`;

const Input = styled.input`
  border: none;
  &:focus {
    outline: none;
  }
  ${mobile({ width: "105px" })}
`;

const Icon = styled.div`
  display: flex;
  color: grey;
  &: hover{
    cursor: pointer;
    color: #108ff0;
  }
`;

const ProductList = () => {
  const location = useLocation();
  const cat = location.pathname.split('/')[2];
  const admin = useSelector(state => state.user.currentUser);
  const [filters, setfilters] = useState({ 'category': ['Product'] });
  const sort = 'newest';
  const [clearFilter, setClearFilter] = useState(false)
  let navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const onSubmit = () => {
    getAllProd(dispatch, filters, "", "", searchText);
    setClearFilter(true);
  }
  
  const clearAllFilters = () => {
    setfilters({ 'category': ['Product'] });
    getAllProd(dispatch, filters, "", "", "");
    setClearFilter(false);
    setSearchText("");
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Container>
      <Announcements />
      <Navbar />
      <FilterContainer>
        <Title>{!cat ? 'Products' : cat}</Title>
        {/* {true && <Button onClick={() => navigate("/addProduct")}>ADD PRODUCT</Button>} */}
        {admin &&  admin.user && admin.user.role === 'true' && <Button onClick={() => navigate("/addProduct")}>ADD PRODUCT</Button>}
      </FilterContainer>
      <FilterContainer>
        <Filter>
          <FilterText>Search {filters.category[0]}:</FilterText>
          <SearchContainer>
            <Input placeholder="Search" onChange={(e) => setSearchText(e.target.value)} onKeyDown={(e) => (e.code==='Enter' && searchText.trim().length >= 3) && onSubmit()} value={searchText} />
            <Icon onClick={() => {searchText.trim().length >= 3 && onSubmit()}}>{searchText.trim().length >= 3 && <SearchIcon style={{ fontSize: 16 }} />}</Icon>
          </SearchContainer>
          {clearFilter && <Icon onClick={() => {clearAllFilters()}}><FilterAltOffIcon style={{ fontSize: 22, marginLeft: '10px' }} /></Icon>}
        </Filter>
      </FilterContainer>
      <Products cat={cat} filters={filters} sort={sort} />
      <NewsLetter />
      <Footer />
    </Container>
  );
};

export default ProductList;
