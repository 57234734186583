import { saathiRequest } from "../../RequestMethods";
import { addMembersFailure, addMembersStart, addMembersSuccess, getMembersFailure, getMembersStart, getMembersSuccess } from "../memberRedux";

export const addFamilyMembers = async (dispatch, item) => {
  dispatch(addMembersStart());
  await saathiRequest
    .post("/saathi/addUpdateMemberData", item)
    .then((resp) => {
      dispatch(addMembersSuccess(resp.data));
    })
    .catch((err) => {
      console.error(err)
      dispatch(addMembersFailure());
    });
};

export const getAllMembers = async (dispatch, id) => {
  dispatch(getMembersStart());
  await saathiRequest
    .get("/saathi/getMembersByOrderId/" + id)
    .then((resp) => {
      dispatch(getMembersSuccess(resp.data.data));
    })
    .catch((err) => {
      console.error(err)
      dispatch(getMembersFailure());
    });
};