import React, { useEffect } from "react";
import styled from "styled-components";
import Announcements from "../components/Announcements";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import Footer from "../components/Footer";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import "../App.css";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
  ${mobile({ fontSize: '24px' })}
`;

const MiddleDesc = styled.div`
  flex: 1;
  margin: 5px;
  color: #1155cc;
  ${mobile({ fontSize: '16px' })}
`;

const Middle = styled.div`
  display: flex;
  flex-direction: space-between;
`;

const MiddleDescTitle = styled.div`
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  ${mobile({ fontSize: '14px' })}
`;

const MiddleDescBody = styled.ol`
${mobile({ fontSize: '12px' })}
`;

const Wrapper = styled.div`
  margin: 20px;
  padding: 0px 20px;
`;

const Desc = styled.p`
  ${mobile({ fontSize: '14px' })}
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: space-between;
  margin-top: 40px;
`;

const BottomDesc = styled.div`
  flex: 1;
`;

const BottomImageContainer = styled.div`
  flex-direction: row;
  margin-bottom: 10px;
`;

const BottomDescTitle = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  text-decoration: underline;
  color: #1155cc;
`;

const OutContainerSlider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2.5rem;
`;

const AboutUs = () => {

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 4, itemsFit: 'contain' }
  };

  const items = [
    <div key={"1"} style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/sup.jpg')} height={200} width={300} alt="" /></div>,
    <div key={"2"} style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/gama.jpg')} height={200} width={300} alt="" /></div>,
    <div key={"3"} style={{ height: '300', width: '500', marginTop: '30px'}}><img src={require('../Assets/Logo/client4.JPG')} style={{ marginLeft: '40px'}} height={150} width={200} alt="" /></div>,
    <div key={"4"} style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/sp.jpg')} height={200} width={200} alt="" /></div>,
    <div key={"5"} style={{ height: '200', width: '500', marginTop: '40px'}}><img src={require('../Assets/Logo/gy1.jpg')} height={150} width={300} alt="" /></div>,
    <div key={"6"} style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/lnt1.jpg')} height={190} width={300} alt="" /></div>,
    <div key={"7"} style={{ height: '300', width: '500'}}><img src={require('../Assets/Logo/Afcons.jfif')} height={190} width={300} alt="" /></div>,
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Announcements />
      <Navbar />
      <Title>About Us</Title>
      <Wrapper>
        <Desc>
          <b>IMPACT TECHNOLOGIES</b> is one of the best IT education, training
          and service provider brands of India that is preferably working in
          three most important domains. It includes IT training services,
          software & embedded product development and consulting service. It is
          the acronym of IT & Others Technology training and education. The
          company is operating from various branches in India. The company
          provides different types of IT training programs like summer training,
          Winter Training and 6 weeks training in various verticals of IT. The
          company has one of the most state of the art Infrastructure with all
          the amenities for students. The main focus of the company is always to
          provide quality IT education and training to the students so that they
          can fit themselves in the industry. The organization provides summer
          training on some of the most cutting edge-technologies like 8051, PIC,
          AVR, ARM, PLC, Arduino, Automation Solutions, Embedded Systems, PCB
          designing, Software Solutions and many more.
        </Desc>
        <div
          style={{
            display: "flex",
            margin: "30px 10px",
            justifyContent: "space-around",
            flexFlow: 'row wrap'
          }}
        >
          <BottomImageContainer>
            <img src={require("../Assets/About/Ard.PNG")} height={200} width={280} alt="" />
          </BottomImageContainer>
          <BottomImageContainer>
            <img src={require("../Assets/About/u-s.jfif")} height={200} width={280} alt="" />
          </BottomImageContainer>
          <BottomImageContainer>
            <img src={require("../Assets/About/OIP.jfif")} height={200} width={280} alt="" />
          </BottomImageContainer>
          <BottomImageContainer>
            <img src={require("../Assets/About/lcd.webp")} style={{ margin: "30px 30px" }} height={130} width={250} alt="" />
          </BottomImageContainer>
        </div>
        <Desc>
          The company has very strong placement cell that assists in placing the
          students in various corporate after the successful completion of the
          course. It may want to bridge the gap between the students and the
          industry. Reason being companies are always looking for very competent
          workforce so that they can provide output from the very first day.
        </Desc>
        <div
          style={{
            display: "flex",
            margin: "30px 10px",
            justifyContent: "space-around",
            flexFlow: 'row wrap'
          }}
        >
          <BottomImageContainer>
            <img src={require("../Assets/About/15.jpg")} height={200} width={300} alt=""/>
          </BottomImageContainer>
          <BottomImageContainer>
            <img src={require("../Assets/About/22.jpg")} height={200} width={300} alt=""/>
          </BottomImageContainer>
          <BottomImageContainer>
            <img src={require("../Assets/About/9.jpg")} height={200} width={300} alt=""/>
          </BottomImageContainer>
        </div>
        <Desc>
          IMPACT TECHNOLOGIES has a very strong team consisting of technical
          trainers, faculties as well as non-teaching staffs who are
          continuously guiding, mentioning, counseling and coaching the students
          by providing them Summer Training, Winter Training as well as 6 weeks
          or months IT Training Program along with certifications. The
          Atmosphere is very friendly between students and teachers so that can
          share their problems and clarify their doubts. The company long 10
          years back and they are continuously expending having overseas
          branches all over the country. The IT industry is booming and there
          are lots of scopes and opportunities for IT professionals who want to
          make their career in this field. But there is a dearth and scarcity of
          skilled manpower in the industry in the present times. Roughly 75% of
          technical graduates are unemployable. IMPACT TECHNOLOGIES is
          continuously striving for excellence to fill in this gap and make some
          value addition by providing quality summer training programs. This
          Training usually takes place from May till August in its all national
          branches. The company has been awarded twice as the “Best IT and
          EMBEDDED TRANING ORGANIZATION."
        </Desc>
        <Desc>
          Our Roadmap starts with our mission, which is enduring. It declares
          our purpose as a company and serves as the standard against with we
          weigh our actions and decisions.
        </Desc>
        <Middle>
          <MiddleDesc>
            <MiddleDescTitle>
              <p>Our Mission</p>
            </MiddleDescTitle>
            <MiddleDescBody>
              <li>To promote technical education in India and Abroad.</li>
              <li>
                To create value and make a different in the field of education.
              </li>
              <li>
                To provide sustainable, advanced technology solution and
                services to our clients.
              </li>
              <li>
                To be an innovative partner of our world-class vendors and our
                customers.
              </li>
              <li>JOB placement offers for our outstanding performers.</li>
            </MiddleDescBody>
          </MiddleDesc>
          <MiddleDesc>
            <MiddleDescTitle>
              <p>Our Values</p>
            </MiddleDescTitle>
            <MiddleDescBody>
              <li>
                Our vision serves as the framework for our roadmap and guides
                aspect of our business by describing what we need to accomplish
                in order to continue achieving sustainable and quality growth.
              </li>
              <li>
                <b>People:</b> Be a great place to work where people are
                inspired to be the best they can be.
              </li>
            </MiddleDescBody>
          </MiddleDesc>
        </Middle>
        <Bottom>
          <BottomDesc>
            <BottomDescTitle>OUR CLIENTS</BottomDescTitle>
          </BottomDesc>
        </Bottom>
      </Wrapper>
      <OutContainerSlider>
        <AliceCarousel
          items={items}
          responsive={responsive}
          // autoHeight
          autoPlay
          // autoPlayControls
          autoPlayStrategy="none"
          autoPlayInterval={2000}
          animationDuration={2000}
          animationType="slide"
          infinite
          touchTracking={false}
          // disableDotsControls
          disableButtonsControls
        />
      </OutContainerSlider>
      <Footer />
    </Container>
  );
};

export default AboutUs;
