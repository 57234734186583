import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import { checkUsernameAvl, signup } from "../redux/APIs/UserAPIs";
import { emptyMessageReducer, emptyReducer } from "../redux/userRedux";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #55caca4f;
  ${mobile({ height: "auto" })}
`;
const Wrapper = styled.div`
  padding: 20px;
  width: 40%;
  background-color: white;
  ${mobile({ width: "70%", margin: "30px" })}
  ${tabletPortrait({ width: "80vw", margin: "30px" })}
  ${tabletLandscape({ width: "80vw", margin: "30px" })}
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  ${mobile({ fontSize: "20px" })}
`;
const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid black")};
  border-radius: 2px;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  &:focus {
    outline: none;
  }
  ${mobile({ padding: "8px", margin: "10px 5px 0px 0px" })}
`;
// const PasswordInput = styled.input`
//   border: none;
//   padding: 10px;
//   ${mobile({ padding: "8px", margin: "10px 5px 0px 0px" })}
// `;

// const InputContainer = styled.span`
//   flex: 0 0 10px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 44%;
//   margin: 20px 10px 0px 0px;
//   border: 1px solid black;
//   border-radius: 2px;
//   padding: 0px;
//   ${mobile({ padding: "8px", margin: "10px 5px 0px 0px" })}
// `;
const Agreement = styled.div`
  font-size: 12px;
  margin: 10px 0px;
`;
const Button = styled.button`
  width: 20%;
  border: none;
  padding: 11px 12px;
  background-color: teal;
  cursor: pointer;
  font-size: 16px;
  color: white;
  &:disabled {
    color: white;
    cursor: not-allowed;
    background-color: #55caca4f;
  }
  ${mobile({ width: "35%", padding: "8px 10px" })}
`;
const Error = styled.div`
  color: red;
  margin-top: 10px;
`;

const Register = () => {
  const response = useSelector((state) => state.user);
  const isFetching = useSelector((state) => state.user.isFetching);
  const error = useSelector((state) => state.user.message);
  // const [errors, setErrors] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const checkUsername = (username) => {
    if (username.length > 3) {
      checkUsernameAvl(dispatch, { username });
    }
  };

  useEffect(() => {
    if(response.error) {
      setErrorMsg(error);
    } else {
      setErrorMsg("");
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    setErrorMsg("");
    dispatch(emptyReducer());
    dispatch(emptyMessageReducer());
    // eslint-disable-next-line
  }, []);

  const onSubmit = (values) => {
    let createdAt = moment.utc().format();
    let user = { ...values, createdAt };
    signup(dispatch, user);
  };

  useEffect(() => {
    if (response.currentUser != null && response.message !== "Verification") {
      navigate("/login");
    } else if (
      response.currentUser != null &&
      response.message === "Verification"
    ) {
      navigate("/verify");
    }
    // eslint-disable-next-line
  }, [response]);

  return (
    <Container>
      <Wrapper>
        <Title>CREATE AN ACCOUNT</Title>
        <Form>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Input
              {...register("firstName", { required: true })}
              isValid={errors?.firstName ? true : false}
              placeholder="First Name"
              name="firstName"
            />
            <Input
              {...register("lastName", { required: true })}
              isValid={errors?.lastName ? true : false}
              placeholder="Last Name"
              name="lastName"
            />
            <>
              <Input
                {...register("username", { required: true })}
                isValid={errors?.username ? true : false}
                placeholder="Username"
                name="username"
                onBlur={(e) => {
                  checkUsername(e.target.value);
                }}
              />
            </>
            <Input
              {...register("email", {
                required: false, // eslint-disable-next-line
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
              type="email"
              placeholder="E-mail"
              name="email"
            />
            <Input
              {...register("mobile", {
                required: true,
                maxLength: 10,
                minLength: 10,
              })}
              isValid={errors?.mobile}
              placeholder="Mobile Number"
              name="mobile"
              type="number"
            />
            <Input
              {...register("password", { required: true, minLength: 5 })}
              isValid={errors?.password ? true : false}
              type={"password"}
              placeholder="Password"
              name="password"
            />
            <Input
              {...register("cnfPassword", {
                required: true,
                validate: (value) => {
                  let password = getValues("password");
                  return password === value;
                },
              })}
              isValid={errors?.cnfPassword ? true : false}
              type="password"
              placeholder="Confirm Password"
              name="cnfPassword"
            />
            <Agreement>
              By creating an account, I consent to the processing of my personal
              data in accordance with the{" "}
              <Link
                to={"/privacyPolicy"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <b>PRIVACY POLICY</b>
              </Link>{" "}
              and{" "}
              <Link
                to={"/terms-conditions"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <b>TERMS AND CONDITIONS</b>
              </Link>
            </Agreement>
            <Button disabled={isFetching} type="submit">
              NEXT
            </Button>
          </form>
        </Form>
        <Error>{errorMsg}</Error>
      </Wrapper>
    </Container>
  );
};

export default Register;
