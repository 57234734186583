import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import _ from "lodash";
import Navbar from "../components/Navbar";
import AnnouncementsSaathi from "../components/AnnouncementsSaathi";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import forge from "node-forge";
import { getSingleProduct } from "../redux/APIs/ProductAPIs";
import { addFamilyMembers, getAllMembers } from "../redux/APIs/MemberAPIs";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { QRCode } from "react-qrcode-logo";
import { userRequest } from "../RequestMethods";
import { loginSuccess } from "../redux/userRedux";
import Footer from "../components/Footer";
import NewsLetter from "../components/NewsLetter";
const secretKey = process.env.REACT_APP_SECRET_KEY;

const encryptString = (text) => {
  const keyBuffer = forge.util.createBuffer(secretKey, "utf8");
  const key = keyBuffer.getBytes();
  const ivBuffer = forge.random.getBytesSync(16); // 16 bytes for AES
  const iv = ivBuffer;

  const cipher = forge.cipher.createCipher("AES-CBC", key);
  cipher.start({ iv });
  cipher.update(forge.util.createBuffer(text, "utf8"));
  cipher.finish();
  const encrypted = forge.util.encode64(iv + cipher.output.getBytes());
  return encrypted;
};

// const decryptString = (encryptedText) => {
//   const keyBuffer = forge.util.createBuffer(secretKey, "utf8");
//   const key = keyBuffer.getBytes();

//   const encryptedBytes = forge.util.decode64(encryptedText);
//   const iv = encryptedBytes.slice(0, 16);
//   const encryptedData = encryptedBytes.slice(16);

//   const decipher = forge.cipher.createDecipher("AES-CBC", key);
//   decipher.start({ iv });
//   decipher.update(forge.util.createBuffer(encryptedData));
//   const success = decipher.finish();

//   return success ? decipher.output.toString("utf8") : null;
// };

const Container = styled.div`
  padding: 0px;
  background-color: #ffebdb;
  min-height: 115vh;
  ${mobile({ padding: "0px" })};
`;

const Title = styled.h1`
  margin: 30px 0;
  text-align: center;
  font-size: 2rem;
  // color: #c55a04;
  ${mobile({ fontSize: "1.3rem" })};
`;

const SubTitle = styled.h3`
  margin: 25px 0px;
  font-size: 1.4rem;
  text-decoration: underline;
  ${mobile({ fontSize: "1rem", margin: "15px 0", fontWeight: "700" })};
`;

const Wrapper = styled.div`
  margin: 20px 80px 40px 80px;
  width: auto;
  height: auto;
  ${mobile({ width: "auto", margin: "30px" })}
  ${tabletPortrait({ width: "auto", margin: "30px" })}
  ${tabletLandscape({ width: "auto", margin: "30px" })}
`;

const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  ${mobile({ flexDirection: "column", gap: "10px" })};
`;

const Input = styled.input`
  flex: 1;
  min-width: 30%;
  margin: 10px 0;
  border-radius: 4px;
  background-color: #ffebdb !important;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid #d98a4a")};
  padding: 10px;
  &:-webkit-autofill {
    background-color: #f0e1b2 !important; /* Change the background color when autofilled */
    transition: background-color 5000s ease-in-out 0s; /* Prevent flickering background on focus */
  }

  &:-webkit-autofill:focus {
    background-color: #f0e1b2 !important; /* Ensure it keeps the same background when focused */
  }
  &:focus {
    outline: none;
    border: 1px solid #d98a4a; // Add blue border on focus
    box-shadow: 0 0 5px #d98a4a; // Add shadow for focus effect
  }
  ${mobile({ minWidth: "100%", padding: "8px" })};
`;

const Select = styled.select`
  flex: 1;
  margin: 10px 0px;
  padding: 10px;
  min-width: 30%;
  border-radius: 4px;
  background-color: #ffebdb;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid #d98a4a")};
  &:-webkit-autofill {
    background-color: #f0e1b2 !important; /* Change the background color when autofilled */
    transition: background-color 5000s ease-in-out 0s; /* Prevent flickering background on focus */
  }

  &:-webkit-autofill:focus {
    background-color: #f0e1b2 !important; /* Ensure it keeps the same background when focused */
  }
  &:focus {
    outline: none;
    border: 1px solid #d98a4a; // Add blue border on focus
    box-shadow: 0 0 5px #d98a4a; // Add shadow for focus effect
  }
  ${mobile({ padding: "8px", margin: "10px 5px 0px 0px", color: "black" })}
`;
const Option = styled.option``;

const Button = styled.button`
  border: none;
  padding: 10px;
  margin: 20px 10px 15px 0px;
  background-color: #d98a4a;
  cursor: pointer;
  font-weight: 500;
  ${mobile({ width: "100%", padding: "8px 0" })};
`;

const ButtonTbl = styled.button`
  width: auto;
  border: none;
  padding: 5px 12px;
  margin: 5px;
  background-color: #d98a4a;
  cursor: pointer;
  font-weight: 500;
  ${mobile({ width: "auto", padding: "6px 10px", margin: "5px" })};
`;

const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  overflow-x: auto;
  ${tabletLandscape({ display: "block", overflowX: "scroll" })};
  ${tabletPortrait({ display: "block", overflowX: "scroll" })};
  ${mobile({ display: "block", overflowX: "scroll" })};
`;

const Th = styled.th`
  border: 1px solid #a24900;
  padding: 8px;
  text-align: left;
  background-color: #d98a4a;
  ${mobile({ fontSize: "0.9rem", padding: "6px" })};
`;

const Td = styled.td`
  border: 1px solid #a24900;
  padding: 8px;
  min-width: ${(props) => (props.width ? props.width : "auto")};
  ${mobile({ fontSize: "0.9rem", padding: "6px" })};
`;

const Message = styled.div`
  margin-top: 10px;
  color: ${(props) => props.color};
  font-size: 1rem;
  text-align: center;
  ${mobile({ fontSize: "0.9rem" })};
`;

const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OuterQRContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${mobile({ flexDirection: "column" })};
  ${tabletPortrait({ flexDirection: "column" })};
  ${tabletLandscape({ flexDirection: "column" })};
`;

const QRText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  ${mobile({ fontSize: "0.9rem" })};
`;

const QRBtnContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })};
`;

const QRLink = styled.button`
  border: none;
  padding: 10px;
  margin: 20px 10px 15px 20px;
  background-color: #d98a4a;
  cursor: pointer;
  font-weight: 500;
  ${mobile({ width: "100%", padding: "8px 0" })};
`;

const RegristrationLoc = () => {
  const response = useSelector((state) => state.registration);
  const product = useSelector((state) => state?.product?.productDetails);
  const saathiMember = useSelector((state) => state.members);
  const user = useSelector((state) => state.user);
  const [subMembers, setSubMembers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isDataUpdated, setIsDataUpdated] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [formType, setFormType] = useState("add");
  const [qrCodeData, setQrCodeData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("Decrypted String:", JSON.parse(decryptString("l0VFDBFxwKf0S6heeGOsLkHAu+A2ioE76vWnOhCGS70WAiW/r79wbsr3SsLAh+W8e6IGut0Uscl3buRVm+jztXzepRiOLNYeBeeLeSFsgh9caCR/MtANJ92yygGuJ2AzziYMMRM7EQBrYECsespJMFymHBS/XjftWb4w4iFGud66GePhxVB2NSTtE9vCOgpU")));

  const onSubmit = () => {
    let cart = {
      cartItems: {
        products: [
          { ...product, username: user.currentUser.user.username, quantity: 1 },
        ],
        quantity: 1,
        total: 50,
        subMembers,
      },
      shippingCharges: 0,
      checkoutType: "Saathi Registration",
    };

    navigate("/checkout", {
      state: cart,
    });
  };

  const updateMembers = () => {
    let order_id = user?.currentUser?.user?.order_id;
    addFamilyMembers(dispatch, { orderId: order_id, members: subMembers });
  };

  const handleAddSubMember = () => {
    const subMemberData = getValues();
    if (editingIndex !== null) {
      const updatedSubMembers = subMembers.map((member, index) =>
        index === editingIndex ? subMemberData : member
      );
      setSubMembers(updatedSubMembers);
      setEditingIndex(null);
    } else {
      let error = false;
      if (subMembers.length > 0) {
        if(subMembers.length >= 6) {
          Swal.fire({
            titleText: "You can add maximum 6 members.",
            icon: "error",
            confirmButtonText: "OK",
            confirmButtonColor: "#d98a4a",
          });
          error = true;
        }
        subMembers.forEach((member) => {
          if (
            member.name.toString().trim() ===
              subMemberData.name.toString().trim() &&
            member.age.toString().trim() ===
              subMemberData.age.toString().trim() &&
            member.mobile.toString().trim() ===
              subMemberData.mobile.toString().trim() &&
            member.address.toString().trim() ===
              subMemberData.address.toString().trim()
          ) {
            Swal.fire({
              titleText: "Member already exists.",
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#d98a4a",
            });
            error = true;
            return;
          }
        });
      }
      if (!error) {
        setSubMembers([...subMembers, { ...subMemberData, isPrimary: false }]);
      }
    }
    reset();
  };

  const handleEditSubMember = (index) => {
    const memberToEdit = subMembers[index];
    Object.keys(memberToEdit).forEach((key) =>
      setValue(key, memberToEdit[key])
    );
    setEditingIndex(index);
  };

  const handleDeleteSubMember = (index) => {
    const filteredSubMembers = subMembers.filter((_, i) => i !== index);
    setSubMembers(filteredSubMembers);
  };

  const handleSetPrimary = (index) => {
    const updatedSubMembers = subMembers.map((member, i) => ({
      ...member,
      isPrimary: i === index,
    }));
    setSubMembers(updatedSubMembers);
  };

  const generateQRCode = async () => {
    const qrCodesDiv = document.getElementById("qrCodes");

    if (qrCodesDiv) {
      const pdf = new jsPDF("p", "pt", "a4");
      const canvases = qrCodesDiv.getElementsByTagName("canvas");
      const paragraphs = qrCodesDiv.getElementsByTagName("p"); // Get all <p> tags for names

      const margin = 20;
      const qrWidth = 140; // Width of each QR code (you can adjust this value)
      const qrHeight = 140; // Height of each QR code (you can adjust this value)
      const columns = 3; // Number of columns per page
      const nameSpacing = 20; // Adjust this for more spacing between QR code and name

      let x = margin;
      let y = margin;

      for (let i = 0; i < canvases.length; i++) {
        const canvas = canvases[i];
        const imgData = canvas.toDataURL("image/png"); // Convert canvas to image data
        const ownerName = paragraphs[i] ? paragraphs[i].textContent : "Unknown"; // Get owner's name from <p> tag

        // Add QR code image
        pdf.addImage(imgData, "PNG", x, y, qrWidth, qrHeight);

        // Add owner's name below the QR code with extra spacing
        pdf.setFontSize(10);
        pdf.text(ownerName, x, y + qrHeight + nameSpacing);

        // Adjust x and y for next QR code
        x += qrWidth + margin;

        // Move to the next row after 3 columns (adjust accordingly for your layout)
        if ((i + 1) % columns === 0) {
          x = margin;
          y += qrHeight + nameSpacing + 20; // Increase space for next row (QR code + name)
        }

        // Add new page if there’s no more space
        if (
          y + qrHeight + nameSpacing + 20 >
          pdf.internal.pageSize.height - margin
        ) {
          pdf.addPage();
          x = margin;
          y = margin;
        }
      }
      // Save the PDF
      pdf.save("qrCodes.pdf");
    } else {
      console.error("QR Codes div not found");
    }
  };

  useEffect(() => {
    if (response.message === "success") {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [response]);

  useEffect(() => {
    const generateQRData = async () => {
      if (saathiMember?.members?.length > 0) {
        // Map members into objects
        const members = saathiMember.members.map((member) => ({
          id: member.id,
          name: member.member_name,
          isPrimary: member.is_primary,
          address: member.res_address,
          gender: member.gender,
          age: member.age,
          email: member.email,
          mobile: member.mobile,
        }));
        setSubMembers(members);

        // Generate QR data with bcrypt hash
        const qrData = await Promise.all(
          saathiMember.members.map(async (member) => {
            const primaryContact = saathiMember.members.find(
              (mem) => mem.is_primary === true
            )?.mobile;

            return {
              name: member.member_name,
              key: encryptString(
                JSON.stringify({
                  id: member.id,
                  name: member.member_name,
                  address: member.res_address,
                  mobile: member.mobile,
                  primaryContact,
                })
              ),
            };
          })
        );

        // console.log("qrData", qrData);
        setQrCodeData(qrData);
      }
    };

    generateQRData();
    // eslint-disable-next-line
  }, [saathiMember]);

  useEffect(() => {
    let orgSubMembers = saathiMember.members.map((member) => {
      return {
        id: member.id,
        name: member.member_name,
        isPrimary: member.is_primary,
        address: member.res_address,
        gender: member.gender,
        age: member.age,
        email: member.email,
        mobile: member.mobile,
      };
    });
    // console.table("orgSubMembers", orgSubMembers);
    // console.table("subMembers", subMembers);
    if (_.isEqual(subMembers, orgSubMembers)) {
      setIsDataUpdated(false);
    } else {
      setIsDataUpdated(true);
    }
    // eslint-disable-next-line
  }, [subMembers]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      Swal.fire({
        titleText: "Please login to continue Registration.",
        showDenyButton: true,
        showConfirmButton: true,
        icon: "question",
        confirmButtonText: "Proceed to Login",
        denyButtonText: "Continue Exploring",
        denyButtonColor: "#95bccb",
        confirmButtonColor: "#1788b9",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login", { state: { url: location.pathname } });
        } else if (result.isDenied || result.isDismissed) {
          navigate("/");
        }
      });
    }
    getSingleProduct(dispatch, 2);
    if (user?.currentUser?.user?.order_id) {
      getAllMembers(dispatch, user?.currentUser?.user?.order_id);
      setFormType("edit");
    } else {
      setValue(
        "name",
        user?.currentUser?.user?.first_name
          ? user?.currentUser?.user?.first_name +
              " " +
              user?.currentUser?.user?.last_name
          : user?.currentUser?.user?.member_name
      );
      setValue("email", user?.currentUser?.user?.email);
      setValue("mobile", user?.currentUser?.user?.mobile);
    }
    userRequest.get("/user/userInfo").then((response) => {
      dispatch(loginSuccess({ user: response.data }));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Navbar />
      <AnnouncementsSaathi />
      <Title>Family Registration for Saathi Milaap</Title>
      <Wrapper>
        <SubTitle>Member Details</SubTitle>
        <form onSubmit={handleSubmit(handleAddSubMember)}>
          <Form>
            <Input
              {...register("name", { required: true })}
              isValid={errors?.name}
              placeholder="Full Name"
            />
            <Input
              {...register("age", { required: true, maxLength: 3 })}
              isValid={errors?.age}
              placeholder="Age"
              type="number"
              min={0}
              max={150}
            />
            <Input
              {...register("email", {
                required: false,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              })}
              isValid={errors?.email}
              type="email"
              placeholder="E-mail"
              name="email"
            />
            <Input
              {...register("mobile", {
                required: true,
                maxLength: 10,
                minLength: 10,
              })}
              isValid={errors?.mobile}
              placeholder="Mobile Number"
              name="mobile"
              type="number"
              min={0}
              max={9999999999}
            />
            <Select
              {...register("gender", {
                required: true,
                validate: (value) => value !== "",
              })}
              isValid={errors?.gender}
            >
              <Option value="">Gender: Select</Option>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
            </Select>
            <Input
              {...register("address", { required: true })}
              isValid={errors?.address}
              placeholder="Address"
            />
          </Form>
          <Button type="submit">
            {editingIndex !== null ? "Update Member" : "Add Member"}
          </Button>
          {saathiMember?.members?.length > 0 && (
            <Button type="button" onClick={() => setShowQR(!showQR)}>
              {showQR ? "Hide" : "View"} QR Codes
            </Button>
          )}
        </form>

        {showQR && (
          <>
            <QRBtnContainer>
              <SubTitle>QR Codes</SubTitle>
              <QRLink onClick={() => generateQRCode()}>Download All QRs</QRLink>
            </QRBtnContainer>
            <OuterQRContainer id="qrCodes">
              {qrCodeData?.map((member, i) => (
                <QRContainer>
                  <QRCode
                    id={`qrCode-${i}`}
                    style={{ margin: "0px 30px 20px 30px" }}
                    value={member.key}
                    size={150}
                    ecLevel="L"
                    fgColor="#d98a4a"
                    logoImage={require("../Assets/Logo/ImpactFav.png")}
                    logoHeight={30}
                    logoWidth={20}
                    logoPadding={4}
                    qrStyle="dots"
                    quietZone={20}
                    eyeRadius={0.2}
                    logoPaddingStyle="square"
                    logoOpacity={1}
                    removeQrCodeBehindLogo={true}
                  />
                  <QRText>{member.name}</QRText>
                </QRContainer>
              ))}
            </OuterQRContainer>
          </>
        )}

        {subMembers.length > 0 && (
          <Table>
            <thead>
              <tr>
                <Th>Name</Th>
                <Th>Age</Th>
                <Th>Email</Th>
                <Th>Mobile</Th>
                <Th>Gender</Th>
                <Th>Address</Th>
                <Th>Primary</Th>
                <Th>Actions</Th>
              </tr>
            </thead>
            <tbody>
              {subMembers.map((member, index) => (
                <tr key={member.name}>
                  <Td width="150px">{member.name}</Td>
                  <Td width="50px">{member.age}</Td>
                  <Td width="200px">{member.email}</Td>
                  <Td width="100px">{member.mobile}</Td>
                  <Td width="100px">{member.gender}</Td>
                  <Td width="200px">{member.address}</Td>
                  <Td width="75px">{member.isPrimary ? "Yes" : "No"}</Td>
                  <Td width="275px">
                    <div style={{ display: "flex" }}>
                      <ButtonTbl onClick={() => handleEditSubMember(index)}>
                        Edit
                      </ButtonTbl>
                      <ButtonTbl onClick={() => handleDeleteSubMember(index)}>
                        Delete
                      </ButtonTbl>
                      {!member.isPrimary && (
                        <ButtonTbl onClick={() => handleSetPrimary(index)}>
                          Set Primary
                        </ButtonTbl>
                      )}
                    </div>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {subMembers.length > 0 && formType === "add" && (
          <Button onClick={onSubmit}>Proceed</Button>
        )}

        {subMembers.length > 0 &&
          isDataUpdated &&
          saathiMember?.members?.length > 0 && (
            <Button onClick={updateMembers}>Update Members</Button>
          )}

        {response.message && (
          <Message color="green">Registration Done Successfully.</Message>
        )}
      </Wrapper>
      <NewsLetter />
      <Footer />
    </Container>
  );
};

export default RegristrationLoc;
