import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Announcements from "../components/Announcements";
import styled from "styled-components";
import { mobile, tabletLandscape, tabletPortrait } from "../responsive";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { states } from "../data";
import { getPinCodeData } from "../redux/APIs/PincodeAPI";
import { resetPincodeData } from "../redux/pinCodeRedux";
import moment from "moment";
import {
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Snackbar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteUserDeliveryLocations,
  editUserDeliveryLocations,
  getUserDeliveryLocations,
  saveDelInfo,
} from "../redux/APIs/CheckoutAPIs";
import Pay from "../components/Pay";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import loader from "../Assets/Loader3.gif";

const Container = styled.div``;
const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  ${mobile({ padding: "10px" })}
`;
const LeftSection = styled.div`
  flex: 1;
  ${mobile({ padding: "10px" })}
`;
const RightSection = styled.div`
  flex: 1;
  ${mobile({ padding: "10px" })}
`;
const Title = styled.h3`
  font-weight: 400;
`;
const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  ${mobile({ width: "100%", alignItems: "center", justifyContent: "center" })}
  ${tabletPortrait({ width: "70%" })}
  ${tabletLandscape({ width: "70%" })}
`;
const Input = styled.input`
  flex: 1;
  max-width: 2500px;
  margin: 20px 10px 0px 0px;
  border-radius: 2px;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid black")};
  padding: 10px;
  &:focus {
    outline: none;
  }
  ${mobile({ padding: "8px", margin: "10px 5px 0px 0px", color: "black" })}
`;
const Button = styled.button`
  width: 22%;
  border: none;
  padding: 10px;
  margin: 20px 10px 0px 0px;
  background-color: #4fb0dd;
  cursor: pointer;
  color: white;
  font-weight: 500;
  ${mobile({ width: "55%", padding: "8px 10px" })}
  ${tabletPortrait({ width: "35%", padding: "8px 10px" })}
  ${tabletLandscape({ width: "35%", padding: "8px 10px" })}
`;
const ButtonCancel = styled.button`
  width: 14%;
  border: none;
  padding: 10px;
  margin: 20px 10px 0px 0px;
  background-color: #6caecd;
  cursor: pointer;
  color: white;
  font-weight: 500;
  ${mobile({ width: "55%", padding: "8px 10px" })}
  ${tabletPortrait({ width: "35%", padding: "8px 10px" })}
  ${tabletLandscape({ width: "35%", padding: "8px 10px" })}
`;
const Select = styled.select`
  flex: 1;
  margin: 20px 10px 0px 0px;
  padding: 10px;
  min-width: 40%;
  border-radius: 2px;
  border: ${(props) => (props.isValid ? "1px red solid" : "1px solid black")};
  &:focus {
    outline: none;
  }
  ${mobile({ padding: "8px", margin: "10px 5px 0px 0px", color: "black" })}
`;

const Loader = styled.img`
  object-fit: cover;
  width: 80px;
  ${mobile({ height: "50vh" })}
`;

const Option = styled.option``;

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAddresses = useSelector((state) => state.checkout);
  const pinCodeData = useSelector((state) => state.pincode.data[0]);
  const orderStatus = useSelector((state) => state.order);

  const [displaySection, setDisplaySection] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showSnackBarDelete, setShowSnackBarDelete] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [id, setId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const cart = useLocation().state;

  const onSubmit = (values) => {
    const createdAt = moment().utc().format();
    const finalValues = { ...values, createdAt };
    if (displaySection === "addAddress") {
      saveDelInfo(dispatch, finalValues);
    } else if (displaySection === "editAddress") {
      let updatedAt = moment().utc().format();
      const finalValues = { ...values, updatedAt };
      editUserDeliveryLocations(dispatch, finalValues, id);
      setId(null);
    }
    reset();
  };

  const handleDeleteClick = (id) => {
    Swal.fire({
      title: "Are you sure want to delete this address ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserDeliveryLocations(dispatch, id);
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleEditClick = (data) => {
    setDisplaySection("editAddress");
    setValue("name", data.full_name);
    setValue("houseNo", data.h_no);
    setValue("area", data.area);
    setValue("landmark", data.landmark);
    setValue("city", data.city);
    setValue("state", data.state);
    setValue("pincode", data.pincode);
    setValue("addType", data.add_type);
    setValue("mobile", data.mobile);
    setId(data.id);
  };

  useEffect(() => {
    if (pinCodeData !== undefined && pinCodeData !== null) {
      setValue("city", pinCodeData.District);
      setValue("state", pinCodeData.State);
    }
    // eslint-disable-next-line
  }, [pinCodeData]);

  useEffect(() => {
    dispatch(resetPincodeData());
    getUserDeliveryLocations(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orderStatus.status === true && orderStatus.message === "Success") {
      navigate("/orderStatus", { state: { gateway: "RazorPay" } });
    }
    // eslint-disable-next-line
  }, [orderStatus]);

  useEffect(() => {
    setDisplaySection(
      userAddresses?.data?.length === 0 ? "addAddress" : "selectAddress"
    );

    if (
      cart.cartItems.products.length === 1 &&
      cart.cartItems.products[0].category === "Service"
    ) {
      setSelectedAddress(0);
    } else {
      setSelectedAddress(
        userAddresses?.data?.length !== 0 &&
          userAddresses?.data?.length !== undefined
          ? userAddresses.data[0].id
          : null
      );
    }

    if (userAddresses.message === "Details deleted Successfully.") {
      setAlertType("success");
      setShowSnackBarDelete(true);
    } else if (userAddresses.message === "Details not deleted.") {
      setAlertType("error");
      setShowSnackBarDelete(true);
    }
    // eslint-disable-next-line
  }, [userAddresses]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackBarDelete(false);
  };

  const vertical = "top";
  const horizontal = "right";

  const renderAddressForm = () => {
    if (userAddresses.isLoading) {
      return (
        <>
          <Loader src={loader} />
          <span>Loading...</span>
        </>
      );
    } else {
      if (displaySection !== "selectAddress") {
        return (
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Form>
              <Input
                {...register("name", { required: true })}
                isValid={errors?.name}
                placeholder="Full Name"
                name="name"
              />
              <Input
                {...register("houseNo", { required: true })}
                isValid={errors?.houseNo}
                placeholder="Flat, House No., Building, Company"
                name="houseNo"
              />
              <Input
                {...register("area", { required: true })}
                isValid={errors?.area}
                placeholder="Area, Street, Sector, Village"
                name="area"
              />
              <Input
                {...register("landmark", { required: true })}
                isValid={errors?.landmark}
                placeholder="Landmark"
                name="landmark"
              />
              <Input
                {...register("pincode", { required: true, max: 999999 })}
                isValid={errors?.pincode}
                placeholder="Pincode"
                name="pincode"
                type="number"
                onChange={(e) => {
                  if (e.target.value.length === 6) {
                    getPinCodeData(dispatch, e.target.value);
                  }
                }}
              />
              <Input
                {...register("mobile", {
                  required: true,
                  maxLength: 10,
                  minLength: 10,
                })}
                isValid={errors?.mobile}
                placeholder="Mobile Number"
                name="mobile"
                type="number"
              />
              <Input
                {...register("city", { required: true })}
                isValid={errors?.city}
                placeholder="City"
                name="city"
              />
              <Select
                {...register("state", {
                  required: true,
                  validate: (value) => value !== "Select",
                })}
                onChange={() => setValue("pincode", "")}
                isValid={errors?.state}
                placeholder="State"
                name="state"
              >
                <Option value="Select">State: Select </Option>
                {states.map((state, index) => (
                  <Option key={state.name} value={state.name}>
                    {state.name}
                  </Option>
                ))}
              </Select>
              <Select
                {...register("addType", {
                  required: true,
                  validate: (value) => value !== "Select",
                })}
                isValid={errors?.addType}
                placeholder="Address Type"
                name="addType"
              >
                <Option value="Select">Address Type: Select </Option>
                <Option key={1} value={"Home"}>
                  Home
                </Option>
                <Option key={2} value={"Office"}>
                  Office
                </Option>
              </Select>
            </Form>
            <Button type="submit">Save Address</Button>
            {userAddresses?.data?.length !== 0 && (
              <ButtonCancel
                onClick={() => {
                  setDisplaySection("selectAddress");
                  reset();
                }}
              >
                Cancel
              </ButtonCancel>
            )}
          </form>
        );
      } else {
        let list = document.getElementsByClassName("AddrList");
        if (list) {
          let newList = Array.from(list);
          newList.map((list) => (list.children[1].style["right"] = "-15px"));
        }

        return (
          <>
            <List sx={{ width: "100%", maxWidth: 360 }}>
              {userAddresses?.data && userAddresses.data instanceof Array
                ? userAddresses.data.map((data, index) => {
                    const secLabel = `${data.h_no}, ${data.area}, Near ${data.landmark}, ${data.city}, ${data.state} - ${data.pincode}`;
                    const primaryLabel = `${data.full_name} - ${data.add_type}`;
                    return (
                      <ListItem
                        className="AddrList"
                        key={data.id}
                        secondaryAction={
                          <>
                            <IconButton
                              edge="start"
                              aria-label="comments"
                              onClick={() => handleEditClick(data)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteClick(data.id)}
                              edge="end"
                              aria-label="comments"
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </>
                        }
                        // disablePadding
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={() => setSelectedAddress(data.id)}
                          dense
                        >
                          <ListItemIcon>
                            <Radio checked={selectedAddress === data.id} />
                          </ListItemIcon>
                          <ListItemText
                            id={index}
                            primary={primaryLabel}
                            secondary={secLabel}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })
                : null}
            </List>

            <Button onClick={() => setDisplaySection("addAddress")}>
              Add New Address
            </Button>
          </>
        );
      }
    }
  };

  return (
    <Container>
      <Announcements />
      <Navbar />
      <Wrapper>
        {cart.cartItems.products.length === 1 &&
        cart.cartItems.products[0].category === "Service" ? null : (
          <LeftSection>
            <Snackbar
              open={showSnackBarDelete}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical, horizontal }}
            >
              {alertType === "success" ? (
                <Alert
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Address Deleted Successfully!
                </Alert>
              ) : (
                <Alert
                  onClose={handleClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Address Deletion Failed!
                </Alert>
              )}
            </Snackbar>
            <Title>
              {displaySection !== "selectAddress"
                ? "Add Delivery Address"
                : "Select Delivery Address"}
            </Title>
            {renderAddressForm()}
          </LeftSection>
        )}
        <RightSection>
          <Pay selectedAddress={selectedAddress} cart={cart}></Pay>
        </RightSection>
      </Wrapper>
    </Container>
  );
};

export default CheckoutPage;
